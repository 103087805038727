$avenir-font-light: "Avenir Next Cyr Light", sans-serif;
$avenir-font-medium: "Avenir Next Cyr Medium", sans-serif;

:root {
  --cardano-bg: #121212 !important;
  --body-background: #121212;
  --primary: #1d1d1d;
  --primary-hover: #272c2ea9;
  --tooltip-background: #9b9b9c;
  --tooltip-font-color: #181c1e;
  --font-color: #515355;
  --font-inactive-color: #9b9b9c;
  --font-highlight: #eff0f7;
  --font-warn: #c35724;
  --hover-color: #2a4c74;
  --button-default: #30a987;
  --button-hover-default: #121212;
  --button-disabled: #12234d;
  --input-disabled: rgba(51, 65, 85, 1);
  --border-color-selected: #ffd700;
}
